import React from "react";
import { 
    Section3Container,
    Section3Content,
    Section3ContentText,
    Section3ContentTabs,
    Section3ContentTabContent,
    Section3ContentTabContentIcon,
    Section3ContentTabContentText
 } from "./Section3Elements";

 import icon1 from "../../images/sec3icon1.svg";
 import icon2 from "../../images/sec3icon2.svg";


const Section3 = () =>{
    return(
        <div>
            <Section3Container>
                <Section3Content>
                    <Section3ContentText>
                        Earn Passive Income in a Virtual World
                    </Section3ContentText>
                    <Section3ContentTabs>
                        <Section3ContentTabContent>
                            <Section3ContentTabContentIcon>
                                <img className='Sec3icon1' src = {icon1} alt=''></img>
                            </Section3ContentTabContentIcon>
                            <Section3ContentTabContentText>
                                <h3>Auto Property Pricing</h3>
                                <h5>Receive an estimated rent value of your space so you always price it just right</h5>
                            </Section3ContentTabContentText>
                        </Section3ContentTabContent>
                        <Section3ContentTabContent>
                            <Section3ContentTabContentIcon>
                                <img className='Sec3icon1' src = {icon2} alt=''></img>
                            </Section3ContentTabContentIcon>
                            <Section3ContentTabContentText>
                                <h3>Easy Exposure</h3>
                                <h5>Get you space infront of popular brands and businesses around the world</h5>
                            </Section3ContentTabContentText>
                        </Section3ContentTabContent>
                    </Section3ContentTabs>
                </Section3Content>
            </Section3Container>
        </div>
    );
};

export default Section3;
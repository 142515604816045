import styled from "styled-components";

export const Section4Container = styled.div`
  height: 800px;
  padding: 0px 10px;

  @media screen and (max-width: 480px) {
    height: auto;
    padding: 20px 10px;
  }
`;

export const Section4Content = styled.div`
    max-width: 1200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px auto;

    @media screen and (max-width: 480px){
        flex-direction: column;
    }
`;


export const Section4ContentTop = styled.div`
    width: 80%;
    margin: 0px auto;
    img{
      width: 100%;
    }
    .responsive{
      position: absolute;
      transform: scale(0);
    }

    @media screen and (max-width:480px){
      .nonResponsive{
      position: absolute;
      transform: scale(0);
      }
      .responsive{
      position: relative;
      transform: scale(1);
    }
    }
`;

export const Section4ContentBottom = styled.div`
  font-size: 35px;
  font-weight: 600;

  @media screen and (max-width:480px){
    font-size: 18px;
    
  }
`;

import styled from "styled-components";

export const Section2Container = styled.div`
  width: 100%;
  height: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

    @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 480px) {
    height: auto;
  }
`;

export const Section2Content = styled.div`
  max-width: 1200px;
  margin: 0px auto;
  padding: 0px 10px;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 1px solid rgb(239, 239, 239, 1);

  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 480px) {
    padding: 5px;
  }
`;
export const Section2ContentTop = styled.div`
  font-size: 30px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  text-align: center;
  position: relative;
  display: flex;

  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 480px) {
    font-size: 14px;
    margin: 20px 0px;
  }
`;

export const Section2ContentBottom = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;


@media screen and (max-width: 768px) {
  .icon1{
      width: 15%;
      margin: 20px;
    }
  }
  @media screen and (max-width: 480px) {
    .icon1{
      width: 15%;
      margin: 10px;
    }
  }
`;
export const Section2Divider = styled.div``;

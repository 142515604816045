import styled from "styled-components";
import Bg from "../../images/Dot.svg";

export const HeroSectionContainer = styled.div`
  height: 700px;
  width: auto;
  z-index: 1;
  overflow: hidden;
  position: relative;
  background: url(${Bg}) 17% 80% no-repeat;

  @media screen and (max-width: 1024px) {
    height: 900px;
    background: white;

  }

  @media screen and (max-width: 768px) {
    width: auto;
    height: 800px;
    background: white;
  }
  @media screen and (max-width: 480px) {
    height: 550px;
    background: white;
  }
`;

export const HeroContent = styled.div`
  max-width: 1200px;
  margin: 0px auto;
  padding: 0px 10px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 100%;
  border-bottom: 1px solid rgb(239, 239, 239, 1);

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    height: 100%;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: 100%;
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;
export const HeroContentLeft = styled.div`
  width: 40%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;

  @media screen and (max-width: 1024px) {
    width: 100%;
    flex: 2;
    align-items: center;
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    flex: 1;
    align-items: center;
    text-align: center;
    margin-top: 20px;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    flex: 2;
    align-items: center;
    text-align: center;
  }
`;

export const HeroContentH1 = styled.div`
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 0px;
  font-family: 'Roboto', sans-serif;
  flex: 0.25;

  @media screen and (max-width: 1024px) {
    flex-direction: row;
    font-size: 42px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: row;
    font-size: 40px;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    font-size: 25px;
    margin-bottom: 20px;
  }
`;

export const HeroContentP = styled.div`
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  color: #565656;
  margin-bottom: 25px;

  @media screen and (max-width: 1024px) {
    font-size: 25px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 480px) {
    font-size: 15px;
    margin-bottom: 5px;
  }
`;

export const HeroContentBtn = styled.div`
  width: 115px;
  font-family: 'DM Sans', sans-serif;
  border-radius: 50px;
  background: #3D00B7;
  white-space: nowrap;
  padding: 12px 30px;
  font-size: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  color: #FFFFFF;

  &:hover{
    transition: all 0.2s ease-in-out;
    background: #FFFFFF;
    color: #3D00B7;
    border: 2px solid #3D00B7;
  }
  
  @media screen and (max-width: 1024px) {
    margin-top: 20px;
    font-size: 20px;
    padding: 15px 30px;
  }

  @media screen and (max-width: 480px) {
    margin-top: 20px;
    font-size: 18px;
    padding: 8px 20px;
  }
`;

export const HeroContentRight = styled.div`
  width: 50%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: 1024px) {
    width: 100%;
    margin-top: 0;

    .heroImage{
    width: 95%;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 0;

    .heroImage{
    width: 100%;
    }
  }

  @media screen and (max-width: 480px) {
    flex: 60;
    margin-top: 0;

    .heroImage{
    width: 100%;
    }
  }
`;

export const Divider = styled.div`
`;

import styled from "styled-components";

export const Section3Container = styled.div `
    height: 330px;
    width: 100%;
    background-color: rgba(217,224,236,0.2);
    margin: 0px 0px;

  @media screen and (max-width: 768px) {
    height: 475px;
  }
  @media screen and (max-width: 480px) {
    height: 375px;
  }
`;
export const Section3Content = styled.div `
    max-width: 1200px;
    height: 100%;
    margin: 0px auto;
    padding: 0px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
    justify-content: center;
  }
`;
export const Section3ContentText = styled.div `
    margin-bottom: 25px;
    font-size: 30px;
    font-weight: 800;
    flex: 1;
    font-family: 'Roboto', sans-serif;
    

    @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 480px) {
      font-family: 'Roboto', sans-serif;
      font-size: 18px;
      flex: none;
      text-align: center;
  }
`;
export const Section3ContentTabs = styled.div `
    flex: 2;
    display: flex;

    @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
    flex: none;
  }
`;
export const Section3ContentTabContent = styled.div `
    display: flex;

    @media screen and (max-width: 480px) {
            margin: 20px 0px;
     }
`;
export const Section3ContentTabContentIcon = styled.div `
    margin:5px 10px;

     
`;
export const Section3ContentTabContentText = styled.div `
    h3{
        font-size: 20px;
        margin: 10px 0px;
    }
    h5{
        font-size: 14px;
        font-weight: 300;
        color: #696969;
    }

    @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 480px) {
    h3{
        font-size: 16px;
        margin: 10px 0px;
    }
    h5{
        font-size: 12px;
        font-weight: 300;
        color: #696969;
    }
  }
`;
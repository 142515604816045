import React from "react";
import {
  Section4Container,
  Section4Content,
  Section4ContentTop,
  Section4ContentBottom,
} from "./Section4Elements";
import iPadimg from "../../images/ipad-img.png";
import iPhoneimg from "../../images/iphone-img.png";


const Section4 = () => {
  return (
    <div>
      <Section4Container>
        <Section4Content>
          <Section4ContentTop>
              <img className="nonResponsive" src={iPadimg} alt=""></img>
              <img className="responsive" src={iPhoneimg} alt=""></img>
          </Section4ContentTop>
          <Section4ContentBottom>
            List And Rent Effortlesly
          </Section4ContentBottom>
        </Section4Content>
      </Section4Container>
    </div>
  );
};

export default Section4;

import React from "react";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavItem,
  NavMenu,
  NavLinks,
  NavBtn,
  NavBtnLink1,
  NavBtnLink2,
  Divider,
  Vertical,
} from "./NavbarElements";

const Navbar = ({toggle}) => {
  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLogo to="/">WIKHOP</NavLogo>
          <Vertical></Vertical>
          <MobileIcon onClick={toggle}>
            <HiOutlineMenuAlt4 />
          </MobileIcon>
          <NavMenu>
            {/* <NavItem>
              <NavLinks to="about">About</NavLinks>
            </NavItem> */}
          </NavMenu>
          <NavBtn>
            <NavBtnLink1 to="/signup">Join Waitlist</NavBtnLink1>
            <NavBtnLink2 to="/">Contact Us</NavBtnLink2>
          </NavBtn>
        </NavbarContainer>
      </Nav>
      <Divider></Divider>
    </>
  );
};

export default Navbar;

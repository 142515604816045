import styled from "styled-components";
import{Link as LinkR} from "react-router-dom";

export const FooterContainer = styled.div`
  height: 350px;
  width: 100%;
`;
export const FooterContent = styled.div`
  max-width: 1200px;
  height: 100%;
  margin: 0px auto;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width:480px){
    flex-direction: column;
    justify-content: space-evenly;
  }
`;
export const FooterContentLeft = styled.div`

  width: 30%;
  h2{
    font-size: 24px;
    font-weight: 800;
  }
  h5{
    font-size: 14px;
    font-weight: normal;
    color: #565656;
  }

  @media screen and (max-width:480px){
    width: 100%;
      }
`;
export const FooterContentRight = styled.div`
  h2{
    font-size: 18px;
    font-weight: 500;
  }
  h5{
    font-size: 14px;
    font-weight: 300;
  }
  @media screen and (max-width: 480px){
    width: 100%;

  }
`;

export const FooterIcons = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-between;
`;

export const FooterRightContentInput = styled.div`
  width: 370px;
  height: 50px;
  border: 1px solid #F1F1F1;
  border-radius: 300px;
  display: flex;
  @media screen and (max-width: 480px){
    width: 100%;
    height: 40px;

  }
`;

export const Textinput = styled.input`
  font-family: 'DM Sans', sans-serif;
  color: #959595;
  border: none;
  outline: none;
  padding: 0;
  margin: 10px 30px;

    @media screen and (max-width: 480px){
    width: 60%;
    margin: 5px 15px;
  }
`;
export const InputBtn = styled.div`
  margin-left: 24px;
  margin-top: 1px;
  font-family: 'DM Sans', sans-serif;
  border-radius: 50px;
  background: #2639ED;
  white-space: nowrap;
  padding: 16px 25px;
  font-size: 13px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  color: #FFFFFF;

    @media screen and (max-width: 480px){
      margin: 0;
      font-size: 1rem;
      padding: 7px 25px;
      margin-left: 10px;
  }
`;

export const FooterCopyright = styled.div`
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #A4A4A4;
  padding-bottom: 10px;

  @media screen and (max-width: 768px){

  }
  @media screen and (max-width: 480px){
      font-size: 11px;
  }
`;
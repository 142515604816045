import React from "react";
import {
  Section2Container,
  Section2Content,
  Section2ContentTop,
  Section2ContentBottom,
} from "./Section2Elements";
import icon1 from "../../images/sec2icon1.png";
import icon2 from "../../images/sec2icon2.png";
import icon3 from "../../images/sec2icon3.png";
import icon4 from "../../images/sec2icon4.png";
import icon5 from "../../images/sec2icon5.png";
import icon6 from "../../images/sec2icon6.png";
import icon7 from "../../images/sec2icon7.png";
import icon8 from "../../images/sec2icon8.png";

const Section2 = () => {
  return (
    <div>
      <Section2Container>
        <Section2Content>
          <Section2ContentTop>
            Companies Already Marketing in the Metaverse
          </Section2ContentTop>
          <Section2ContentBottom>
            <img className='icon1' src = {icon1} alt=''></img>
            <img className='icon1' src = {icon2} alt=''></img>
            <img className='icon1' src = {icon3} alt=''></img>
            <img className='icon1' src = {icon4} alt=''></img>
            <img className='icon1' src = {icon5} alt=''></img>
            <img className='icon1' src = {icon6} alt=''></img>
            <img className='icon1' src = {icon7} alt=''></img>
            <img className='icon1' src = {icon8} alt=''></img>
          </Section2ContentBottom>
        </Section2Content>
      </Section2Container>
    </div>
  );
};

export default Section2;

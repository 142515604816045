import React from "react";
import {
  FooterContainer,
  FooterContent,
  FooterContentLeft,
  FooterContentRight,
  FooterIcons,
  FooterRightContentInput,
  Textinput,
  InputBtn,
  FooterCopyright
} from "./FooterElements";

 import facebookIcon from "../../images/facebook.png";
 import twitterIcon from "../../images/twitter.png";
  import linkedinIcon from "../../images/linkedin.png";

const Footer = () => {
  return (
    <>
      <FooterContainer>
        <FooterContent>
          <FooterContentLeft>
              <h2>WIKHOP</h2>
              <h5>
              The worlds first virtual advertisement space rentals. Earn a passive income and help companies around the world enter a new era of virtual marketing.
              </h5>
              <FooterIcons>
                   <a href="www.facebook.com"><img className='Sec5Img' src = {facebookIcon} alt=''></img></a> 
                    <a href="www.twitter.com"><img className='Sec5icon' src = {twitterIcon} alt=''></img></a>
                    <a href="www.linkedin.com"> <img className='Sec5icon' src = {linkedinIcon} alt=''></img></a>
              </FooterIcons>
          </FooterContentLeft>
          <FooterContentRight>
              <h2>Stay In The Loop</h2>
              <h5>Join our mailing list to stay in the loop with our newest feature releases.</h5>
              <FooterRightContentInput>
                <Textinput placeholder="Enter your email"></Textinput>
                <InputBtn>Subscribe Now</InputBtn>
              </FooterRightContentInput>
          </FooterContentRight>
        </FooterContent>
        <FooterCopyright>
          &copy; {new Date().getFullYear()} Wikhop. All Rights Reserved.
        </FooterCopyright>
      </FooterContainer>
    </>
  );
};
export default Footer;

import styled from "styled-components";
import{Link as LinkR} from "react-router-dom";

export const Nav = styled.div`
  background: #FFFFFF;
  height: 80px;
  /* margin-top: -80px; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 960px){
    transition: 0.8s all ease;
  }
`
export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
`

export const NavLogo = styled(LinkR)`
  color: #3D00B7;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 0px;
  font-weight: bold;
  text-decoration: none;
`

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px){
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #3D00B7
  }
`

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`
export const NavItem = styled.li`
  height: 80px;
`
export const NavLinks = styled.div`
  font-family: 'DM Sans', sans-serif;
  color: #000000;
  display: flex;
  align-items: center;
  text-decoration: none; 
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  $:active{
    border-bottom: 3px solid #3D00B7
  }
`

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px){
    display: none;
  }
`

export const NavBtnLink1 = styled(LinkR)`
  font-family: 'DM Sans', sans-serif;
  margin-right: 10px;
  border-radius: 50px;
  background: #3D00B7;
  white-space: nowrap;
  padding: 13px 28px;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  color: #FFFFFF;

  &:hover{
    transition: all 0.2s ease-in-out;
    background: #FFFFFF;
    color: #3D00B7;
    border: 2px solid #3D00B7;
  }
`

export const NavBtnLink2 = styled(LinkR)`
  font-family: 'DM Sans', sans-serif;
  border-radius: 50px;
  background: #FFFFFF;
  white-space: nowrap;
  padding: 10px 22px;
  font-size: 16px;
  outline: none;
  border: 2px solid #3D00B7;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  color: #3D00B7;

  &:hover{
    transition: all 0.2s ease-in-out;
    background: #3D00B7;
    color: #FFFFFF;
  }
`

export const Divider = styled.div`
  border: 1px solid rgb(239, 239, 239, 1);
`

export const Vertical = styled.div`
  border-left: 2px solid rgb(239, 239, 239, 1);
  margin-top: 1%;
  height: 50px;
  position: absolute;
  left: 30%;

  @media screen and (max-width: 1024px) {
    display: none;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`

import React from "react";
import {
  HeroSectionContainer,
  HeroContent,
  HeroContentLeft,
  HeroContentH1,
  HeroContentP,
  HeroContentBtn,
  HeroContentRight,
  Divider,
} from "./HeroElements";
import HeroRightImg from '../../images/herorightimg.svg';

const HeroSection = () => {
  return (
    <div>
      <HeroSectionContainer>
        <HeroContent>
          <HeroContentLeft>
            <HeroContentH1>Rent and List Ad Spaces in the Metaverse</HeroContentH1>
            <HeroContentP>The worlds first virtual advertisement space rentals. Earn a passive income and help companies around the world enter a new era of virtual marketing.</HeroContentP>
            <HeroContentBtn>Join Waitlist</HeroContentBtn>
          </HeroContentLeft>
          <HeroContentRight>
            <img className='heroImage' src = {HeroRightImg} alt=''></img>
          </HeroContentRight>
        </HeroContent>
      </HeroSectionContainer>
      <Divider></Divider>
    </div>
  );
};

export default HeroSection;

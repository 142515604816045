import React from 'react';
import {
    Section5Container,
    Section5Content,
    Section5ContentImg,
    Section5ImgContent,
    Section5ImgNike,
    Section5ImgGucci,
    Section5ImgCocacola,
    Section5ContentText,
    Section5ContentTextButton

} from "./Section5Elements";
import {
    NavBtnLink1,NavBtnLink2
} from '../Navbar/NavbarElements'
import basketCourt from "../../images/basketCourt.png";
import nike from "../../images/nike.png"; 
import robot from "../../images/robot.png";
import gucci from "../../images/gucci.png";
import vending from "../../images/vending.png";
import cocacola from "../../images/cocacola.png";


const Section5 = () => {
    return(
        <div>
            <Section5Container>
                <Section5Content>
                    <Section5ContentImg>
                        <Section5ImgNike>
                            <Section5ImgContent>
                                <img className='Sec5Img' src = {basketCourt} alt=''></img>
                                <img className='Sec5icon' src = {nike} alt=''></img>
                            </Section5ImgContent>                            
                        </Section5ImgNike>
                        <Section5ImgGucci>
                            <Section5ImgContent>
                                <img className='Sec5Img' src = {robot} alt=''></img>
                                <img className='Sec5icon' src = {gucci} alt=''></img>
                            </Section5ImgContent>                           
                        </Section5ImgGucci>
                        <Section5ImgCocacola>
                            <Section5ImgContent>
                                <img className='Sec5Img' src = {vending} alt=''></img>
                                <img className='Sec5icon' src = {cocacola} alt=''></img>
                            </Section5ImgContent>
                        </Section5ImgCocacola>
                    </Section5ContentImg>
                    <Section5ContentText>
                        <h2>
                            Advertise Your Business the Modern Way
                        </h2>
                        <h5>
                            Get a headstart on the future of marketing. Join countless other giants who have already taken the leap. Contact us now to secure your spot and recive discounted prices on prime virtual spaces once we launch. 
                        </h5>
                        <Section5ContentTextButton>
                            <NavBtnLink1 to="/">Join Waitlist</NavBtnLink1>
                            <NavBtnLink2 to="/">Contact Us</NavBtnLink2>
                        </Section5ContentTextButton>
                    </Section5ContentText>
                </Section5Content>
            </Section5Container>
        </div>
    );
};

export default Section5;

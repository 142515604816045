import styled from 'styled-components';

export const Section5Container = styled.div `
    height: 700px;
    padding: 0px 10px;
    background-color: rgba(217,224,236,0.2);

    @media screen and (max-width: 480px){
        height: auto;
        padding: 20px 10px;
    }
`;
export const Section5Content = styled.div `
    max-width: 1200px;
    height: 100%;
    display: flex;
    margin: 0px auto;

    @media screen and (max-width: 480px){
        flex-direction: column;
    }
`;
export const Section5ContentImg = styled.div `
    flex: 1.75;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    align-items: center;
    justify-items: center;
    .Sec5Img{
        position: relative;
    }
    .Sec5icon{
        position: absolute;
        transform: translate(-50%,50%);
        bottom: 0;
    }
    @media screen and (max-width: 480px){
        order: 2;
        align-items: flex-start;
    }
`;
export const Section5ImgContent = styled.div `
    position: relative;
    @media screen and (max-width: 480px){
        margin-top: 50px;
        .Sec5Img{
            width: 95%;
        }
         .Sec5icon{
             transform: translate(-100%, -10%);
            width: 30%;
        }
    }
`;
export const Section5ImgNike = styled.div `
    position: relative;
    grid-area: 1 / 1 / 2 / 2;

    @media screen and (max-width: 480px) {
        grid-area: 1 / 1 / 2 / 3;
    }
`;
export const Section5ImgGucci = styled.div `
    position: relative;

    grid-area: 1 / 2 / 3 / 3;
    @media screen and (max-width: 480px) {
        grid-area: 2 / 2 / 3 / 3;
    }

`;
export const Section5ImgCocacola = styled.div `
    position: relative;
    grid-area: 2 / 1 / 3 / 2;

    @media screen and (max-width: 480px) {
        grid-area: 2 / 1 / 3 / 2;
    }
`;
export const Section5ContentText = styled.div `
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    h2{
        font-size:28px ;
    }
    h5{
        font-size: 16px;
        font-weight: 400;
        color:#636363;
        margin: 20px 0px 50px 0px;
    }

    @media screen and (max-width: 480px) {
        align-items: center;
        text-align: center;
    h2{
        font-size: 18px;
    }
    h5{
        font-size: 12px;
    }

  }
`;
export const Section5ContentTextButton = styled.div `

`;
